import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import {
  ConsoleSpanExporter,
  SimpleSpanProcessor,
} from "@opentelemetry/sdk-trace-base";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { DocumentLoadInstrumentation } from "@opentelemetry/instrumentation-document-load";
import { registerInstrumentations } from "@opentelemetry/instrumentation";

// Set up the OTLP exporter
const exporter = new OTLPTraceExporter({
  url: "http://20.164.150.34:4318/v1/traces", // Update with your Tempo endpoint
});

// Create a tracer provider
const provider = new WebTracerProvider();

// Add the exporter to the provider
provider.addSpanProcessor(new SimpleSpanProcessor(exporter));

// Optionally, you can add a console exporter to see the spans in the console
provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));

// Register the provider
provider.register();

// Register instrumentations
registerInstrumentations({
  instrumentations: [
    new FetchInstrumentation({
      propagateTraceHeaderCorsUrls: [
        "https://api.dev.backoffice.katsupay.com", // Update with your backend URL
      ],
    }),
    new DocumentLoadInstrumentation(),
  ],
});
